import * as React from 'react'
import { useTranslator } from '../hooks/Translator'
import ReactDragListView from 'react-drag-listview'
import { Page } from './components/Page'
import {
    Col,
    Row,
    ListGroup,
    ListGroupItem,
    ButtonGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Spinner
} from 'reactstrap'
import { PageContext } from './Context/PageContext'
import {
    QuestionTypes,
    QuestionnairePagesImages,
    SubQuestionTypes
} from './constants'
import '../assets/scss/designer/index.scss'

export const QuestionnaireDesigner = ({
    defaultModel,
    onSaveButtonClicked,
    isLoading,
    savingInprocess
}) => {
    const t = useTranslator()
    const lng = 'en'

    const [firstInit, setFirstInit] = React.useState(false)

    const [elements, setElements] = React.useState([])

    const elementsRefs = React.useRef(new Map())

    const [elementsTree, setElementsTree] = React.useState([])

    const [currentEditingPage, setCurrentEditingPage] = React.useState(null)

    const [showPageImageSelectorModal, setShowPageImageSelectorModal] =
        React.useState(null)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    const findParentAndReSort = (parentId, children, fromIndex, toIndex) => {
        for (let i = 0; i < children.length; i++) {
            if (children[i].id === parentId) {
                children[i].elements = reorder(
                    children[i].elements,
                    fromIndex,
                    toIndex
                )
                return
            }

            if (children[i].elements && children[i].elements.length > 0) {
                findParentAndReSort(
                    parentId,
                    children[i].elements,
                    fromIndex,
                    toIndex
                )
            }
        }
    }

    const onDragEnd = (fromIndex, toIndex, parentId = null) => {
        if (toIndex < 0) return

        setElementsTree((currentState) => {
            const children = [...currentState]

            if (!parentId) {
                return reorder(children, fromIndex, toIndex)
            }

            findParentAndReSort(parentId, children, fromIndex, toIndex)

            return children
        })
    }

    const generateRandomID = () => {
        return `el-${Date.now()}-${Math.random()}`
    }

    const scrollToElement = (elementType, id) => {
        setTimeout(() => {
            const elRef = elementsRefs.current.get(id)
            if (elRef) {
                elRef.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest'
                })
                elRef.classList.add('item-notify')
                setTimeout(() => {
                    elRef.classList.remove('item-notify')
                }, 1500)
            }
        }, 500)
    }

    const findParentAndPushChild = (parentId, children, item) => {
        if (!parentId) {
            children.push(item)
            return
        }

        for (let i = 0; i < children.length; i++) {
            if (children[i].id === parentId) {
                if (!children[i]?.elements) {
                    children[i].elements = [item]
                } else {
                    children[i].elements.push(item)
                }
                return
            }

            if (children[i].elements && children[i].elements.length > 0) {
                findParentAndPushChild(parentId, children[i].elements, item)
            }
        }
    }

    const findAndDeleteChildFromTree = (elementID, children) => {
        for (let i = 0; i < children.length; i++) {
            if (children[i].id === elementID) {
                children.splice(i, 1)
                return
            }

            if (children[i].elements && children[i].elements.length > 0) {
                findAndDeleteChildFromTree(elementID, children[i].elements)
            }
        }
    }

    const addElementToTree = (elementType, elementProps) => {
        setElementsTree((currentState) => {
            const children = [...currentState]

            findParentAndPushChild(elementProps.parentElementID, children, {
                type: elementType,
                ...elementProps
            })
            return children
        })
    }

    const deleteElementFromTree = (elementID) => {
        setElementsTree((currentState) => {
            const children = [...currentState]
            findAndDeleteChildFromTree(elementID, children)
            return children
        })
    }

    const deleteFromElements = (elementID) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex((el) => el.id === elementID)

            if (elementIndex >= 0) {
                if (
                    newState[elementIndex].elementType === 'page' &&
                    currentEditingPage === newState[elementIndex].id
                ) {
                    setCurrentEditingPage(null)
                }
                newState.splice(elementIndex, 1)
            }

            return newState
        })
    }

    const addElement = (elementType, elseProps) => {
        const id = generateRandomID()

        setElements((currentState) => {
            const newState = [...currentState]

            newState.push({
                id: id,
                elementType: elementType,
                state: {
                    editTitle: false,
                    collapsed: false
                },
                ...elseProps
            })

            if (elseProps.parentElementID) {
                const parentIndex = newState.findIndex(
                    (el) => el.id === elseProps.parentElementID
                )
                if (parentIndex > -1) {
                    newState[parentIndex].state.collapsed = true
                }
            }

            return newState
        })

        addElementToTree(elementType, {
            ...elseProps,
            id: id
        })

        scrollToElement(elementType, id)

        return id
    }

    const deleteElement = (elementID) => {
        deleteElementFromTree(elementID)
        deleteFromElements(elementID)
    }

    const setElementField = (elementID, field, value) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex((el) => el.id === elementID)

            if (elementIndex >= 0) {
                newState[elementIndex][field] = value
            }

            return newState
        })
    }

    const setElementStateField = (elementID, field, value) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex((el) => el.id === elementID)

            if (elementIndex >= 0) {
                if (!newState[elementIndex]?.state) {
                    newState[elementIndex].state = {
                        [field]: value
                    }
                } else {
                    newState[elementIndex].state[field] = value
                }
            }

            return newState
        })
    }

    const addQuestionOption = (questionID, optionSettings) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                if (!newState[questionIndex].options) {
                    newState[questionIndex].options = [optionSettings]
                } else {
                    newState[questionIndex].options.push(optionSettings)
                }
            }

            return newState
        })
    }

    const deleteQuestionOption = (questionID, optionIndex) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                newState[questionIndex].options.splice(optionIndex, 1)
            }

            return newState
        })
    }

    const setQuestionOptionField = (questionID, optionIndex, field, value) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                newState[elementIndex].options[optionIndex][field] = value
            }

            return newState
        })
    }

    const setQuestionOptionStateField = (
        questionID,
        optionIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                if (!newState[elementIndex].options[optionIndex].state) {
                    newState[elementIndex].options[optionIndex].state = {
                        [field]: value
                    }
                } else {
                    newState[elementIndex].options[optionIndex].state[field] =
                        value
                }
            }

            return newState
        })
    }

    const addQuestionOptionChild = (questionID, optionIndex) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                const option = newState[elementIndex].options[optionIndex]
                const childNum =
                    newState[elementIndex].options[optionIndex].children
                        .length + 1

                const subQType =
                    option.state?.newSubQuestionType ||
                    SubQuestionTypes.find((sType) => sType.isDefault)

                newState[elementIndex].options[optionIndex].children.push({
                    id: generateRandomID(),
                    title: `${t('Sub question')} ${childNum}`,
                    placeholder: subQType.placeholder,
                    questionType: subQType.value,
                    isMandatory: false,
                    isDeletable: true,
                    state: {
                        editTitle: false
                    },
                    options: []
                })
            }

            return newState
        })
    }

    const deleteQuestionOptionChild = (questionID, optionIndex, childIndex) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                newState[questionIndex].options[optionIndex].children.splice(
                    childIndex,
                    1
                )
            }

            return newState
        })
    }

    const setQuestionOptionChildField = (
        questionID,
        optionIndex,
        childIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                newState[questionIndex].options[optionIndex].children[
                    childIndex
                ][field] = value
            }

            return newState
        })
    }

    const setQuestionOptionChildStateField = (
        questionID,
        optionIndex,
        childIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                if (
                    !newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].state
                ) {
                    newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].state = {
                        [field]: value
                    }
                } else {
                    newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].state[field] = value
                }
            }

            return newState
        })
    }

    const addSubQuestionOption = (
        questionID,
        optionIndex,
        childIndex,
        optionSettings
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                if (
                    !newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].options
                ) {
                    newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].options = [optionSettings]
                } else {
                    newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].options.push(optionSettings)
                }
            }

            return newState
        })
    }

    const deleteSubQuestionOption = (
        questionID,
        optionIndex,
        childIndex,
        subOptionIndex
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                newState[questionIndex].options[optionIndex].children[
                    childIndex
                ].options.splice(subOptionIndex, 1)
            }

            return newState
        })
    }

    const setSubQustionOptionField = (
        questionID,
        optionIndex,
        childIndex,
        subOptionIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                newState[elementIndex].options[optionIndex].children[
                    childIndex
                ].options[subOptionIndex][field] = value
            }

            return newState
        })
    }

    const setSubQustionOptionStateField = (
        questionID,
        optionIndex,
        childIndex,
        subOptionIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                if (
                    !newState[elementIndex].options[optionIndex].children[
                        childIndex
                    ].options[subOptionIndex].state
                ) {
                    newState[elementIndex].options[optionIndex].children[
                        childIndex
                    ].options[subOptionIndex].state = {
                        [field]: value
                    }
                } else {
                    newState[elementIndex].options[optionIndex].children[
                        childIndex
                    ].options[subOptionIndex].state[field] = value
                }
            }

            return newState
        })
    }

    const handleAddNewQuestionToActivePage = (qType) => {
        let pageID = currentEditingPage
        if (!pageID) {
            pageID = elements.filter((el) => el.elementType === 'page')[0]?.id

            if (!pageID) {
                pageID = addElement('page', {
                    title: 'Tab Title',
                    image: 'change_management'
                })
            }
        }

        setTimeout(() => {
            addElement('question', {
                questionType: qType.value,
                title: t('Question Title'),
                placeholder: qType.placeholder,
                parentElementID: pageID
            })
        }, 250)
    }

    const handleAddNewPage = () => {
        addElement('page', {
            title: 'Tab Title',
            image: 'change_management'
        })
    }

    const handleAddNewPanelToActivePage = () => {
        let pageID = currentEditingPage
        if (!pageID) {
            pageID = elements.filter((el) => el.elementType === 'page')[0]?.id
            if (!pageID) {
                pageID = addElement('page', {
                    title: t('Tab Title'),
                    image: 'change_management'
                })
            }
        }

        setTimeout(() => {
            addElement('panel', {
                title: t('Panel Title'),
                parentElementID: pageID
            })
        }, 250)
    }

    const getTreeElements = (treeElements) => {
        const result = []
        for (let i = 0; i < treeElements.length; i++) {
            const item = treeElements[i]
            const itemInfos = elements.find((el) => el.id === item.id)

            if (itemInfos) {
                const itemTree = {
                    ...itemInfos,
                    elementType: itemInfos.elementType
                }

                if (item.elements && item.elements.length > 0) {
                    itemTree.elements = getTreeElements(item.elements)
                }

                result.push(itemTree)
            }
        }

        return result
    }

    const handleSaveQuestionnaire = () => {
        onSaveButtonClicked &&
            onSaveButtonClicked(getTreeElements(elementsTree))
    }

    React.useEffect(() => {
        if (!isLoading && defaultModel?.length > 0 && !firstInit) {
            const elementsResult = []
            const treeResult = []

            const findAndInit = (children, parentElementID = undefined) => {
                for (let i = 0; i < children.length; i++) {
                    const el = children[i]
                    const id = generateRandomID()

                    elementsResult.push({
                        ...el,
                        id: id,
                        elementType: el.elementType,
                        state: {
                            editTitle: false,
                            collapsed: false
                        },
                        elements: []
                    })

                    findParentAndPushChild(parentElementID, treeResult, {
                        type: el.elementType,
                        id: id,
                        elements: []
                    })

                    if (el?.elements?.length > 0) {
                        findAndInit(el.elements, id)
                    }
                }
            }

            findAndInit(defaultModel)

            if (elementsResult.length > 0) {
                elementsResult[0].state.collapsed = true

                setElements(elementsResult)
                setElementsTree(treeResult)
                setCurrentEditingPage(elementsResult[0].id)
            }

            setFirstInit(true)
        }
    }, [isLoading, defaultModel, firstInit])

    const resultPages = elementsTree.map((treeItem) => {
        return {
            ...elements.find((element) => element.id === treeItem.id),
            elements: treeItem.elements
        }
    })

    if (elementsRefs.current.size !== elements.length) {
        elements.forEach((el) => {
            if (!elementsRefs.current.has(el.id)) {
                elementsRefs.current.set(el.id, React.createRef())
            }
        })
    }

    return (
        <React.Fragment>
            {!isLoading ? (
                <PageContext.Provider
                    value={{
                        elements,
                        elementsTree,
                        elementsRefs,
                        currentEditingPage,

                        setCurrentEditingPage,

                        setElements,
                        addElement,
                        deleteElement,
                        setElementField,
                        setElementStateField,

                        addQuestionOption,
                        deleteQuestionOption,
                        setQuestionOptionField,
                        setQuestionOptionStateField,

                        addQuestionOptionChild,
                        setQuestionOptionChildField,
                        deleteQuestionOptionChild,
                        setQuestionOptionChildStateField,

                        addSubQuestionOption,
                        setSubQustionOptionField,
                        setSubQustionOptionStateField,
                        deleteSubQuestionOption,

                        onDragEnd,
                        reorder
                    }}
                >
                    <div className='smartintegrity__questionnaire__designer-container'>
                        <Row>
                            <Col sm='4' md='4' lg='3'>
                                <div className='smartintegrity__questionnaire__designer-questions-types-container'>
                                    <ListGroup flush>
                                        <ListGroupItem
                                            onClick={handleAddNewPage}
                                            action
                                            key='new-page'
                                            className='d-flex align-items-center'
                                        >
                                            <i className='ri-page-separator me-2' />
                                            {t('New Questions Tab')}
                                        </ListGroupItem>

                                        <ListGroupItem
                                            onClick={
                                                handleAddNewPanelToActivePage
                                            }
                                            action
                                            key='new-panel'
                                            className='d-flex align-items-center'
                                        >
                                            <i className='ri-fullscreen-line me-2' />
                                            {t('New Panel')}
                                        </ListGroupItem>

                                        {QuestionTypes.map((qType, index) => {
                                            return (
                                                <ListGroupItem
                                                    onClick={() =>
                                                        handleAddNewQuestionToActivePage(
                                                            qType
                                                        )
                                                    }
                                                    action
                                                    key={index}
                                                    className='d-flex align-items-center'
                                                >
                                                    <i
                                                        className={`${qType.icon} me-2`}
                                                    />
                                                    {t(qType.title)}
                                                </ListGroupItem>
                                            )
                                        })}
                                    </ListGroup>
                                </div>
                            </Col>

                            <Col sm='8' md='8' lg='9'>
                                <Row>
                                    <Col sm='12' className='mb-2'>
                                        <ButtonGroup>
                                            <Button color='secondary' outline>
                                                <i className='ri-arrow-go-back-line me-1' />
                                                {t('Undo')}
                                            </Button>
                                            <Button color='secondary' outline>
                                                <i className='ri-arrow-go-forward-line me-1' />
                                                {t('Redo')}
                                            </Button>
                                            <Button
                                                disabled={savingInprocess}
                                                color='primary'
                                                onClick={
                                                    handleSaveQuestionnaire
                                                }
                                            >
                                                {savingInprocess ? (
                                                    <React.Fragment>
                                                        <Spinner
                                                            className='me-1'
                                                            size='sm'
                                                        />
                                                        {t('Saving')}...
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <i className='ri-save-line me-1' />
                                                        {t('Save Changes')}
                                                    </React.Fragment>
                                                )}
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col sm='12'>
                                        <ReactDragListView
                                            nodeSelector='.smartintegrity__questionnaire__designer-page'
                                            handleSelector='.smartintegrity__questionnaire__designer-drag-btn.page'
                                            onDragEnd={(fromIndex, toIndex) => {
                                                onDragEnd(fromIndex, toIndex)
                                            }}
                                            lineClassName='smartintegrity__questionnaire__designer-page-placeholder'
                                        >
                                            {resultPages.map(
                                                (page, pageIndex) => {
                                                    return (
                                                        <Page
                                                            key={pageIndex}
                                                            t={t}
                                                            {...page}
                                                            onChangeImageBtnClicked={(
                                                                pageInfo
                                                            ) => {
                                                                setShowPageImageSelectorModal(
                                                                    pageInfo
                                                                )
                                                            }}
                                                        />
                                                    )
                                                }
                                            )}
                                        </ReactDragListView>

                                        {/* <div>
                                            <button
                                                onClick={handleAddNewPage}
                                                className='smartintegrity__questionnaire__designer-add-new-page-btn'
                                            >
                                                {t('Add New Questions Tab')}
                                            </button>
                                        </div> */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Modal
                            className='smartintegrity__questionnaire__designer-container-page-image-selector-modal'
                            isOpen={!!showPageImageSelectorModal}
                            toggle={() => setShowPageImageSelectorModal(null)}
                        >
                            <ModalHeader
                                toggle={() =>
                                    setShowPageImageSelectorModal(null)
                                }
                            >
                                {t('Select image')}
                            </ModalHeader>
                            <ModalBody>
                                <ListGroup
                                    flush
                                    style={{
                                        maxHeight: '300px',
                                        overflow: 'auto'
                                    }}
                                >
                                    {Object.keys(QuestionnairePagesImages).map(
                                        (pageImage, pageImageIndex) => {
                                            return (
                                                <ListGroupItem
                                                    action
                                                    onClick={() => {
                                                        setElementField(
                                                            showPageImageSelectorModal.id,
                                                            'image',
                                                            pageImage
                                                        )
                                                        setTimeout(() => {
                                                            setShowPageImageSelectorModal(
                                                                null
                                                            )
                                                        }, 100)
                                                    }}
                                                    color={
                                                        pageImage ===
                                                        showPageImageSelectorModal?.image
                                                            ? 'info'
                                                            : null
                                                    }
                                                    key={pageImageIndex}
                                                    className='d-flex align-items-center'
                                                >
                                                    <img
                                                        src={
                                                            QuestionnairePagesImages[
                                                                pageImage
                                                            ]?.src
                                                        }
                                                        className='me-2'
                                                    />
                                                    <span>
                                                        {t(
                                                            QuestionnairePagesImages[
                                                                pageImage
                                                            ]?.title
                                                        )}
                                                    </span>
                                                </ListGroupItem>
                                            )
                                        }
                                    )}
                                </ListGroup>
                            </ModalBody>
                        </Modal>
                    </div>
                </PageContext.Provider>
            ) : (
                <Row>
                    <Col sm='4' md='3'>
                        <div
                            style={{ width: '100%', height: '450px' }}
                            className='dt-field dt-skeleton'
                        />
                    </Col>
                    <Col sm='8' md='9'>
                        <div
                            style={{ width: '100%', height: '600px' }}
                            className='dt-field dt-skeleton'
                        />
                    </Col>
                </Row>
            )}
        </React.Fragment>
    )
}

import * as React from 'react'

function useTranslator() {
    const [translator, setTranslator] = React.useState(null)

    const t = React.useCallback((content) => {
        if (translator) {
            return translator.translate(content)
        }

        return content
    })

    React.useEffect(() => {
        import('react-i18next').then(({ getI18n }) => {
            const { translator } = getI18n()

            setTranslator(translator)
        })
    }, [])

    return t
}

export { useTranslator }

import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslator } from '../../hooks/Translator'
import { Row, Col, Button } from 'reactstrap'
import { ReactStrapQuestion } from '../../components/Question'
import Accordion from 'react-bootstrap/Accordion'
import {
    makeQuestionsAnswersReadyToSend,
    renderQuestionAnswerByType,
    guessQuestionAnsweredByAnswerType
} from '../../helpers/questionnaire'

export const MultiTabQuestionnaire = ({
    questions,
    defaultValues = {},
    onReadyToSubmit,
    settings,
    className,
    onChange
}) => {
    const t = useTranslator()
    const methods = useForm({
        defaultValues: defaultValues
    })
    const [isMounted, setIsMounted] = React.useState(false)
    const [activeTabIndex, setActiveTabIndex] = React.useState(0)
    const [tabsPercents, setTabsPercents] = React.useState([])
    const [tabsAnsweredQuestions, setTabsAnsweredQuestions] =
        React.useState(null)

    const onSubmit = (data) => {
        onReadyToSubmit &&
            onReadyToSubmit(
                makeQuestionsAnswersReadyToSend({
                    multiTab: true,
                    questions: questions,
                    values: data
                })
            )
    }

    const handleQuestionAnswerStatusChanged = (
        newAnswer,
        questionName,
        question,
        tabIndex
    ) => {
        // console.log(questions[tabIndex], tabIndex)
        // const answerStatus = guessQuestionAnsweredByAnswerType(newAnswer)

        // setTabsAnsweredQuestions((currentAnsweredQuestions) => {
        //     if (currentAnsweredQuestions) {
        //         if (!answerStatus) {
        //             return {
        //                 ...currentAnsweredQuestions,
        //                 [tabIndex]: [
        //                     ...(
        //                         currentAnsweredQuestions[tabIndex] || []
        //                     ).filter((q) => {
        //                         return q !== question.id
        //                     })
        //                 ]
        //             }
        //         } else {
        //             const prevAns = (
        //                 currentAnsweredQuestions[tabIndex] || []
        //             ).findIndex((q) => {
        //                 return q === question.id
        //             })

        //             if (prevAns < 0) {
        //                 return {
        //                     ...currentAnsweredQuestions,
        //                     [tabIndex]: [
        //                         ...(currentAnsweredQuestions[tabIndex] || []),
        //                         question.id
        //                     ]
        //                 }
        //             } else {
        //                 return {
        //                     ...currentAnsweredQuestions
        //                 }
        //             }
        //         }
        //     }
        // })

        if (isMounted && onChange) {
            const checkQuestionIsForTab = (tabIndex, questionId) => {
                const parentQuestionId = parseInt(
                    questionId.toString().split('-')[0]
                )

                return !!questions[tabIndex].questions.find((q) => {
                    return parseInt(q.id) === parentQuestionId
                })
            }

            let questionnaireCompleted = true
            let tabTotalRequiredQuestions = 0
            let tabAnsweredRequiredQuestions = 0

            const {
                control: { _fields }
            } = methods

            const latestValues = methods.getValues()

            for (const id in _fields) {
                const field = _fields[id]._f
                if (field.mount && field?.required) {
                    const questionAnswered = guessQuestionAnsweredByAnswerType(
                        latestValues[id]
                    )

                    if (checkQuestionIsForTab(tabIndex, id)) {
                        tabTotalRequiredQuestions++

                        if (questionAnswered) {
                            tabAnsweredRequiredQuestions++
                        } else {
                            if (id === questionName) {
                                tabAnsweredRequiredQuestions--
                            }
                        }
                    }

                    if (!questionAnswered) {
                        questionnaireCompleted = false
                    }
                }
            }

            // console.log('questionnaire completed: ' + questionnaireCompleted)
            // console.log(
            //     'tabTotalRequiredQuestions: ' + tabTotalRequiredQuestions
            // )
            // console.log(
            //     'tabAnsweredRequiredQuestions: ' + tabAnsweredRequiredQuestions
            // )

            // onChange({
            //     changedItem: {
            //         question: questionName,
            //         answer: renderQuestionAnswerByType(
            //             newAnswer,
            //             question,
            //             methods.getValues()
            //         )
            //     },
            //     questionnaireCompleted: questionnaireCompleted
            // })
        }
    }

    React.useEffect(() => {
        const tabsQuestionsAnswers = []
        questions.forEach((tabContent, tabIndex) => {
            const tabQuestions = tabContent?.questions || []
            const tabAnsweredQuestions = []

            tabQuestions.forEach((tabQuestion) => {
                if (defaultValues[tabQuestion?.id]) {
                    tabAnsweredQuestions.push(tabQuestion?.id)
                }
            })

            tabsQuestionsAnswers[tabIndex] = tabAnsweredQuestions
        })

        setTabsAnsweredQuestions(tabsQuestionsAnswers)

        setIsMounted(true)
    }, [])

    if (!isMounted) return null

    const { tabSettings, buttonsSettings } = settings || {}

    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <Accordion defaultActiveKey={activeTabIndex}>
                    {questions.map((tab, tabIndex) => {
                        return (
                            <Accordion.Item
                                key={tabIndex}
                                onClick={() => setActiveTabIndex(0)}
                                className={
                                    activeTabIndex === tabIndex ? 'active' : ''
                                }
                                eventKey={tabIndex}
                            >
                                <Accordion.Header>
                                    <div className='section-header'>
                                        <span>
                                            {`${
                                                tabSettings?.showNumber &&
                                                tabIndex + 1 + '.'
                                            } ${t(tab.title)}`}
                                        </span>
                                        {tabSettings?.showProgressBar && (
                                            <div
                                                className='completed-percent'
                                                style={{
                                                    width: `${
                                                        (((
                                                            (tabsAnsweredQuestions &&
                                                                tabsAnsweredQuestions[
                                                                    tabIndex
                                                                ]) ||
                                                            []
                                                        ).length || 0) /
                                                            tab.questions
                                                                .length) *
                                                        100
                                                    }%`
                                                }}
                                            />
                                        )}
                                    </div>
                                </Accordion.Header>

                                <Accordion.Body>
                                    {(tab?.questions || []).map(
                                        (question, index) => {
                                            return (
                                                <ReactStrapQuestion
                                                    key={index}
                                                    question={question}
                                                    settings={settings}
                                                    onAnswerStatusChanged={(
                                                        questionName,
                                                        newAnswer,
                                                        question
                                                    ) => {
                                                        setTimeout(() => {
                                                            handleQuestionAnswerStatusChanged(
                                                                newAnswer,
                                                                questionName,
                                                                question,
                                                                tabIndex
                                                            )
                                                        }, 500)
                                                    }}
                                                />
                                            )
                                        }
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>

                <Row>
                    <Col
                        sm='12'
                        className='d-flex justify-content-between mt-2 mb-2'
                    >
                        {!!buttonsSettings.back?.display && (
                            <Button
                                onClick={() => {
                                    if (buttonsSettings.back?.onClick) {
                                        buttonsSettings.back.onClick()
                                    }
                                }}
                                disabled={!buttonsSettings.back?.enable}
                                color='btn btn-secondary'
                                className={`${buttonsSettings?.back?.customClass}`}
                            >
                                {t(buttonsSettings?.back?.title || 'Back')}
                            </Button>
                        )}

                        {!!buttonsSettings.submit?.display && (
                            <Button
                                disabled={!buttonsSettings.submit?.enable}
                                type='submit'
                                color='primary'
                                className={`${buttonsSettings?.submit?.customClass}`}
                            >
                                {t(buttonsSettings?.submit?.title || 'Submit')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </form>
        </FormProvider>
    )
}
